export const shippingOptions = [
  {
    id: "pickup",
    shipping_rate_data: {
      type: "fixed_amount",
      fixed_amount: {
        amount: 0,
        currency: "nzd",
      },
      display_name: "Pickup (by request only)",
      delivery_estimate: {
        minimum: {
          unit: "business_day",
          value: 1,
        },
        maximum: {
          unit: "business_day",
          value: 2,
        },
      },
    },
  },
  {
    id: "within_auckland",
    shipping_rate_data: {
      type: "fixed_amount",
      fixed_amount: {
        amount: 899,
        currency: "nzd",
      },
      display_name: "Within Auckland",
      delivery_estimate: {
        minimum: {
          unit: "business_day",
          value: 1,
        },
        maximum: {
          unit: "business_day",
          value: 2,
        },
      },
    },
  },
  {
    id: "auckland_rural",
    shipping_rate_data: {
      type: "fixed_amount",
      fixed_amount: {
        amount: 1299,
        currency: "nzd",
      },
      display_name: "Auckland Rural",
      delivery_estimate: {
        minimum: {
          unit: "business_day",
          value: 1,
        },
        maximum: {
          unit: "business_day",
          value: 3,
        },
      },
    },
  },
  {
    id: "north_island",
    shipping_rate_data: {
      type: "fixed_amount",
      fixed_amount: {
        amount: 1299,
        currency: "nzd",
      },
      display_name: "North Island",
      delivery_estimate: {
        minimum: {
          unit: "business_day",
          value: 1,
        },
        maximum: {
          unit: "business_day",
          value: 2,
        },
      },
    },
  },
  {
    id: "north_island_rural",
    shipping_rate_data: {
      type: "fixed_amount",
      fixed_amount: {
        amount: 1899,
        currency: "nzd",
      },
      display_name: "North Island Rural",
      delivery_estimate: {
        minimum: {
          unit: "business_day",
          value: 1,
        },
        maximum: {
          unit: "business_day",
          value: 3,
        },
      },
    },
  },
  {
    id: "south_island",
    shipping_rate_data: {
      type: "fixed_amount",
      fixed_amount: {
        amount: 2599,
        currency: "nzd",
      },
      display_name: "South Island",
      delivery_estimate: {
        minimum: {
          unit: "business_day",
          value: 1,
        },
        maximum: {
          unit: "business_day",
          value: 2,
        },
      },
    },
  },
  {
    id: "south_island_rural",
    shipping_rate_data: {
      type: "fixed_amount",
      fixed_amount: {
        amount: 3199,
        currency: "nzd",
      },
      display_name: "South Island Rural",
      delivery_estimate: {
        minimum: {
          unit: "business_day",
          value: 1,
        },
        maximum: {
          unit: "business_day",
          value: 3,
        },
      },
    },
  },
]